@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap');

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

small {
  line-height: 1;
}

#root {
  height: 100%;
}

h4.ant-typography {
  margin: 0;
}

/* OVERRIDE FOR ReactMarkdown */
p {
  margin-top: 3px;
}

/* antd BUG */
.ant-card-body::before, .ant-card-body::after {
  content: none !important;
}

/* antd Overrides */
.ant-select-dropdown {
  overflow: visible !important;
}